/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import {
    UPDATE_CUSTOMER_EMAIL_APPLE,
    UPDATE_DETAILS_SET_CH,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_LANDMARKS,
    UPDATE_SELECTED_DATE,
    UPDATE_SELECTED_LANDMARK,
    UPDATE_SHIPPING_FIELDS,
    UPDATE_ZENRELO_DETAILS
} from './Checkout.action';

/** @namespace MasafiFrontend/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    shippingFields: {},
    email: '',
    isEmailAvailable: true,
    landmarks: [],
    selectedLandmark: {},
    selectedDate: '',
    AppleEmail: '',
    streetAddresstoSend: '',
    autocomplete: '',
    latitude: 25.1229858,
    longitude: 55.2088846,
    landmarkErrorCheckout: false,
    autocompleteVal: '',
    creditCardDetails: [],
    zenreloDetails: []
});

/** @namespace MasafiFrontend/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SELECTED_DATE: {
        const { date } = action;
        return {
            ...state,
            selectedDate: date
        };
    }
    case UPDATE_ZENRELO_DETAILS: {
        const { zenreloDetails } = action;
        return {
            ...state,
            zenreloDetails
        };
    }
    case UPDATE_DETAILS_SET_CH: {
        const { creditCardDetails } = action;
        return {
            ...state,
            creditCardDetails
        };
    }
    case UPDATE_LANDMARKS: {
        const { landmarks } = action;
        return {
            ...state,
            landmarks
        };
    }
    case UPDATE_SELECTED_LANDMARK: {
        const { landmark } = action;
        return {
            ...state,
            selectedLandmark: landmark
        };
    }
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields
        };

    case UPDATE_EMAIL:
        const { email } = action;

        return {
            ...state,
            email
        };

    case UPDATE_CUSTOMER_EMAIL_APPLE:
        const { emailApple } = action;
        return {
            ...state,
            emailApple
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;

        return {
            ...state,
            isEmailAvailable
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
