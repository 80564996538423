/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import {
    UPDATE_EMAIL as SOURCE_UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE as SOURCE_UPDATE_EMAIL_AVAILABLE,
    UPDATE_SHIPPING_FIELDS as SOURCE_UPDATE_SHIPPING_FIELDS,
    updateEmail as sourceUpdateEmail,
    updateEmailAvailable as sourceUpdateEmailAvailable,
    updateShippingFields as sourceUpdateShippingFields
} from 'SourceStore/Checkout/Checkout.action';

export const UPDATE_SHIPPING_FIELDS = SOURCE_UPDATE_SHIPPING_FIELDS;

export const UPDATE_EMAIL = SOURCE_UPDATE_EMAIL;

export const UPDATE_EMAIL_AVAILABLE = SOURCE_UPDATE_EMAIL_AVAILABLE;

export const UPDATE_LANDMARKS = 'UPDATE_LANDMARKS';

export const UPDATE_SELECTED_LANDMARK = 'UPDATE_SELECTED_LANDMARK';

export const UPDATE_SELECTED_DATE = 'UPDATE_SELECTED_DATE';

export const UPDATE_CUSTOMER_EMAIL_APPLE = 'UPDATE_CUSTOMER_EMAIL_APPLE';

export const updateShippingFields = sourceUpdateShippingFields;

export const updateEmail = sourceUpdateEmail;

export const updateEmailAvailable = sourceUpdateEmailAvailable;

export const UPDATE_LATITUDE = 'UPDATE_LATITUDE';

export const UPDATE_LONGITUDE = 'UPDATE_LONGITUDE';

export const UPDATE_AUTOCOMPLETE = 'UPDATE_AUTOCOMPLETE';

export const UPDATE_MAP_STREET = 'UPDATE_MAP_STREET';

export const UPDATE_AUTO_VAL = 'UPDATE_AUTO_VAL';

export const UPDATE_DETAILS_SET_CH = 'UPDATE_DETAILS_SET_CH';

export const UPDATE_ZENRELO_DETAILS = 'UPDATE_ZENRELO_DETAILS';

/** @namespace MasafiFrontend/Store/Checkout/Action/updateLandmarks */
export const updateLandmarks = (landmarks) => ({
    type: UPDATE_LANDMARKS,
    landmarks
});

/** @namespace MasafiFrontend/Store/Checkout/Action/updateSelectedLandmark */
export const updateSelectedLandmark = (landmark) => ({
    type: UPDATE_SELECTED_LANDMARK,
    landmark
});

/** @namespace MasafiFrontend/Store/Checkout/Action/updateSelectedDate */
export const updateSelectedDate = (date) => ({
    type: UPDATE_SELECTED_DATE,
    date
});

/** @namespace MasafiFrontend/Store/Checkout/Action/updateAppleEmail */
export const updateAppleEmail = (appleEmail) => ({
    type: UPDATE_CUSTOMER_EMAIL_APPLE,
    appleEmail
});
/** @namespace MasafiFrontend/Store/Checkout/Action/updateLatitude */
export const updateLatitude = (latitude) => ({
    type: UPDATE_LATITUDE,
    latitude
});

/** @namespace MasafiFrontend/Store/Checkout/Action/updateLongitude */
export const updateLongitude = (longitude) => ({
    type: UPDATE_LONGITUDE,
    longitude
});

/** @namespace MasafiFrontend/Store/Checkout/Action/udpateAutoComplete */
export const udpateAutoComplete = (autocomplete) => ({
    type: UPDATE_AUTOCOMPLETE,
    autocomplete
});

/** @namespace MasafiFrontend/Store/Checkout/Action/updateMapStreet */
export const updateMapStreet = (streetAddresstoSend) => ({
    type: UPDATE_MAP_STREET,
    streetAddresstoSend
});

/** @namespace MasafiFrontend/Store/Checkout/Action/updateAutoVal */
export const updateAutoVal = (autocompleteVal) => ({
    type: UPDATE_AUTO_VAL,
    autocompleteVal
});

/** @namespace MasafiFrontend/Store/Checkout/Action/updatecreditCardDetails */
export const updatecreditCardDetails = (creditCardDetails) => ({
    type: UPDATE_DETAILS_SET_CH,
    creditCardDetails
});
/** @namespace MasafiFrontend/Store/Checkout/Action/updateZenreloDetails */
export const updateZenreloDetails = (zenreloDetails) => ({
    type: UPDATE_ZENRELO_DETAILS,
    zenreloDetails
});
